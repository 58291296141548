<template>
    <!-- <div v-if="data && data.text && showCookie" class="cookie banner-block banner_bottom" :class="rootClasses"> -->
    <div :class="rootClasses">
        <div class="container">
            <div class="row">
                <div class="col-lg-10">
                    <div
                        class="cs-cookie-banner__text"
                        :class="
                            data.titleAlign ? 'text-' + data.titleAlign : ''
                        "
                        v-html="data.text"
                    />
                </div>
                <div class="col-lg-2">
                    <button
                        v-if="data.button"
                        class="cs-cookie-banner__btn outline"
                        @click="() => {}"
                    >
                        {{ data.button }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            showCookie: true
        }
    },
    computed: {
        rootClasses() {
            let result = {
                ['cs-cookie-banner']: true
            }

            if (this.data.shadowEnabled === true)
                result['shadow-container'] = true

            if (this.data.background != 'white')
                result[`background-${this.data.background}`] = true

            if (this.data.additionalClass) {
                let temp = this.data.additionalClass.split(' ')
                if (Array.isArray(temp) && temp.length > 0) {
                    temp.forEach((className) => {
                        result[className] = true
                    })
                }
            }

            return result
        }
    },
    methods: {
        // checkCookies() {
        //     let cookie = this.$cookies.get( window.location.host + '_banner' );
        //     if ( cookie && cookie !== null ) this.showCookie = false;
        //     else this.showCookie = true;
        // },
        // setCookies() {
        //     this.$cookies.set( window.location.host + '_banner', 'true' );
        //     window.location.reload;
        //     this.showCookie = false;
        // }
    },
    mounted() {
        // this.checkCookies();
    }
}
</script>
<style scoped lang="scss">
@import '../styles/main.scss';
.cs-cookie-banner {
    padding: 50px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $theme-color;

    &__text {
        @include text;
        color: $text_color_over_theme_color;
    }

    &__btn {
        margin: 0 auto;
        @include theme-button;
    }

    @media (max-width: 991.98px) {
        &__text {
            margin-bottom: 20px;
        }
        &__btn {
            width: 100%;
        }
    }
}
</style>
